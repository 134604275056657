import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function () {
    const pqrs = ref(null);

    const createPqrs = async (data) => {
        const res = await axios.post('/pqrs/', data);
        if (res.status === 201) {
            pqrs.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const getPqrs = async (id) => {
        const res = await axios.get(`/pqrs/${id}`);
        if (res.status === 200) {
            pqrs.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updatePqrs = async (id, data) => {
        const res = await axios.put(`/pqrs/${id}`, data);
        if (res.status === 200) {
            pqrs.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const closePqrs = async (id) => {
        const res = await axios.post(`/pqrs/close/${id}`);
        if (res.status === 200) {
            pqrs.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        pqrs,
        createPqrs,
        getPqrs,
        updatePqrs,
        closePqrs,
    };
}
